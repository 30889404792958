/******************* Django i18n *******************/
// This is just a check to see whether Django jsi18n has been loaded, to give a
// clear error message rather than (or at least before) a bunch of confusing
// errors later on.
if (!window.django) {
  console.error("window.django not defined - ensure Django's jsi18n is loaded before this script")
}


// Now to set up everything else

// Best not to import all of bootstrap and just uncomment the parts we're actually using:
// https://getbootstrap.com/docs/5.0/customize/optimize/#lean-javascript

// import 'bootstrap/js/dist/alert'
import 'bootstrap/js/dist/button'
import 'bootstrap/js/dist/carousel'
import 'bootstrap/js/dist/base-component'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/popover'
// import 'bootstrap/js/dist/offcanvas'
// import 'bootstrap/js/dist/scrollspy'
// import 'bootstrap/js/dist/tab'
// import 'bootstrap/js/dist/toast'
// import 'bootstrap/js/dist/tooltip'

import './plyr-initialize'
import './mobile-nav'
import './billy-memory-game'

import {
  focusOnElement,
} from './utils'



function docReady(fn) {
  if (document.readyState !== 'loading') {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}
docReady(function() {
  if (window.location.hash) {
    focusOnElement(window.location.hash)
  }
  window.addEventListener('hashchange', function(e) {
    focusOnElement(window.location.hash)
  })
})





// We're moving away from having contact forms on every single website and just linking
// to protectchildren.ca's contact form instead, so this stuff having to do with the captcha
// and form validation will probably not be needed.

// import Captcha from './modules/captcha-handler.js'
// import FormValidator from './modules/form-validator.js'
//
// if (document.querySelector('[data-captcha-id]')) {
//   let captcha = new Captcha(document.querySelector('[data-captcha-id]'))
//   captcha.initialize()
// }
//
// // Bootstrap's comment on their validation page doesn't exactly inspire confidence:
// // https://getbootstrap.com/docs/5.2/forms/validation/
// // So I guess we'll do it like this
// if (document.querySelector('[data-form-validate]')) {
//   let form = new FormValidator(document.querySelector('[data-form-validate]'))
//   form.initialize()
// }
