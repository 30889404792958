if (document.querySelector('.deck')) {
	const getElement = document.getElementsByTagName('html');
	let checkLang = getElement[0].getAttribute("lang"); 
	const i18n = {
					'en': {
						modalQustionTitle: "Is this a safe buddy for Billy?",
						modalLastMatch: "You matched all the cards! Great job picking safe buddies for Billy!",
						modalAnswerYes: "Good job! This is someone who takes care of Billy, so this is a safe buddy for Billy.",
						modalAnswerNo: "Good job! Billy is 6 years old and needs someone who can take care of him. This is not a safe buddy for Billy.",
						modalAnswerDefault: "Try again!",
					},
					'fr': {
						modalQustionTitle: "Est-ce un bon compagnon pour Martin?",
						modalLastMatch: "Bravo! Tu as formé toutes les paires et tu as choisi de bons compagnons pour Martin!",
						modalAnswerYes: "Bien joué! Cette personne est capable de veiller sur Martin et elle fera un bon compagnon pour lui.",
						modalAnswerNo: "Bien joué! Martin n’a que 6 ans et il a besoin de quelqu’un pour veiller sur lui. L’illustration ne montre pas un bon compagnon.", 
						modalAnswerDefault: "Essaie encore!",
					}
				 };

	const cardDeck = [
						{
							cardName: 'billy1',
							cardNum: '1',
							cardRelation: 'family',
						},
						{
							cardName: 'billy2',
							cardNum: '2',
							cardRelation: 'family',
						},
						{
							cardName: 'billy3',
							cardNum: '3',
							cardRelation: 'family',
						},
						{
							cardName: 'billy4',
							cardNum: '4',
							cardRelation: 'family',
						},
						{
							cardName: 'billy5',
							cardNum: '5',
							cardRelation: 'family',
						},
						{
							cardName: 'billy6',
							cardNum: '6',
							cardRelation: 'family',
						},
						{
							cardName: 'billy7',
							cardNum: '7',
							cardRelation: 'family',
						},
						{
							cardName: 'billy8',
							cardNum: '8',
							cardRelation: 'family',
						},
						{
							cardName: 'billy9',
							cardNum: '9',
							cardRelation: 'family',
						},
						{
							cardName: 'buddy1_friend',
							cardNum: '1',
							cardRelation: 'friend',
						},
						{
							cardName: 'buddy2_friend',
							cardNum: '2',
							cardRelation: 'friend',
						},
						{
							cardName:'buddy3_brother',
							cardNum: '3',
							cardRelation: 'family',
						},
						{
							cardName:'buddy4_mom',
							cardNum: '4',
							cardRelation: 'family',
						},
						{
							cardName: 'buddy5_grandpa',
							cardNum: '5',
							cardRelation: 'family',
						},
						{
							cardName: 'buddy6_grandma',
							cardNum: '6',
							cardRelation: 'family',
						},
						{
							cardName: 'buddy7_friend',
							cardNum: '7',
							cardRelation: 'friend',
						},
						{
							cardName: 'buddy8_friend',
							cardNum: '8',
							cardRelation: 'friend',
						},
						{
							cardName: 'buddy9_friend',
							cardNum: '9',
							cardRelation: 'family',
						},
					];
	let opened = [];
	const deck = document.querySelector('.deck');
	const gameCardsQTY = cardDeck.length / 2;
	const delay = 500;
	const elementHTML = document.getElementsByTagName('html');
	const elementBody = document.getElementsByTagName('body');
	const customModal = document.querySelector('.customModal');
	const btnModalClose  = document.querySelector('.btnModalClose');
	const modalAnswerYes  = document.querySelector('.modalAnswerYes');
	const modalAnswerNo  = document.querySelector('.modalAnswerNo');
	const btnCont  = document.querySelector('.btnCont');
	const btnOptions  = document.querySelector('.btnOptions');
	const btnOptionYes  = document.querySelector('.btnOptionYes');
	const btnOptionNo  = document.querySelector('.btnOptionNo');
	const btnOptionClose  = document.querySelector('.btnOptionClose');
	const modalAnswerCont  = document.querySelector('.modalAnswerCont');
	const modalAnswer  = document.querySelector('.modalAnswer');
	const photoCont  = document.querySelector('.photoCont');
	const modalQuestionHeader  = document.querySelector('.modalQuestionHeader');
	const modalInnerCont = document.querySelector('.modalInnerCont');

	// Closes and Resets it back
	const closeModal = () => {
		customModal.classList.remove('modalShow');
		modalAnswerCont.innerHTML = '';
		photoCont.innerHTML = '';
		btnOptionYes.classList.remove('correctAnswer');
		btnOptionNo.classList.remove('correctAnswer');
		elementHTML[0].classList.remove('noScroll');
		btnOptionYes.classList.replace('d-none', 'd-inline-block');
		btnOptionNo.classList.replace('d-none', 'd-inline-block');
		btnOptionClose.classList.replace('d-inline-block', 'd-none');
		deck.querySelectorAll('.active').forEach(card => {
			card.classList.remove('open', 'active', 'match');
		});
	};

	const btnRestart = document.querySelector('.btnRestart');
	btnRestart.addEventListener('click', (event) => {
		// Resets it
		opened = [];

		deck.style.pointerEvents = 'none';
		photoCont.innerHTML='';

		deck.querySelectorAll('.card').forEach(card => {
			card.classList.remove('match','open','show','active','firstClicked');
		});

		setTimeout(() => {
			initGame();
			deck.style.pointerEvents = 'auto';
		}, delay);
	});

	btnModalClose.addEventListener('click', closeModal);
	btnOptionClose.addEventListener('click', closeModal);
	customModal.addEventListener('click', (e) => {
		if (e.target === customModal) {
			e.preventDefault();
			closeModal();
		}
	});

	// Shuffle function From http://stackoverflow.com/a/2450976
	function shuffle(array) {
		let currentIndex = array.length, temporaryValue, randomIndex;
		
		while (0 !== currentIndex) {
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
		}
	
		return array;
	}

	function playAgain() {
		if (gameCardsQTY === match) 
		{
			photoCont.innerHTML = '';
			modalQuestionHeader.style.display = 'none';

			const modalLastMatchText = (checkLang === 'en') ? i18n.en.modalLastMatch : i18n.fr.modalLastMatch;
			modalAnswerCont.insertAdjacentHTML('beforeend', `<p class="text-center fw-bold">${modalLastMatchText}</p>`);
		}
	}

	btnOptionYes.addEventListener('click', (event) => {
		if (event.target.classList.contains('correctAnswer'))
		{
			const btnModalAnswerYes = (checkLang === 'en') ? '<p class="modalAnswer modalAnswerYes">'+ i18n.en.modalAnswerYes +'</p>' : '<p class="modalAnswer modalAnswerYes">'+ i18n.fr.modalAnswerYes +'</p>';
			modalAnswerCont.insertAdjacentHTML('beforeend', btnModalAnswerYes);

			deck.querySelectorAll('.active').forEach((el) => {
				el.classList.remove('active');
			});

			match++;
			playAgain();
		} 

		else 
		{
			const btnModalAnswerDefault = (checkLang === 'en') ? '<p class="modalAnswer modalAnswerNo">'+ i18n.en.modalAnswerDefault +'</p>' : '<p class="modalAnswer modalAnswerNo">'+ i18n.fr.modalAnswerDefault +'</p>';
			modalAnswerCont.insertAdjacentHTML('beforeend', btnModalAnswerDefault);
		}

		modalQuestionHeader.style.display = 'none';
		btnOptionYes.classList.add('d-none');
		btnOptionNo.classList.add('d-none');
		btnOptionClose.classList.replace('d-none', 'd-inline-block');
		photoCont.innerHTML = '';
	});

	btnOptionNo.addEventListener('click', (event) => {
		if (event.target.classList.contains('correctAnswer')) 
		{
			const btnModalAnswerYes = (checkLang == 'en') ? '<p class="modalAnswer modalAnswerYes">'+ i18n.en.modalAnswerNo +'</p>' : '<p class="modalAnswer modalAnswerYes">'+ i18n.fr.modalAnswerNo +'</p>';
			modalAnswerCont.insertAdjacentHTML('beforeend', btnModalAnswerYes);
			deck.querySelectorAll('.active').forEach((el) => {
				el.classList.remove('active');
			});
			
			match++;
			playAgain();
		} 
		
		else 
		{
			const btnModalAnswerDefault = (checkLang =='en') ? '<p class="modalAnswer modalAnswerNo">'+ i18n.en.modalAnswerDefault +'</p>' : '<p class="modalAnswer modalAnswerNo">'+ i18n.fr.modalAnswerDefault +'</p>';
			modalAnswerCont.insertAdjacentHTML('beforeend', btnModalAnswerDefault);
		}
	
		modalQuestionHeader.style.display = 'none';
		btnOptionYes.classList.add('d-none');
		btnOptionNo.classList.add('d-none');
		btnOptionClose.classList.replace('d-none', 'd-inline-block');
		photoCont.innerHTML = '';
	});

	// Initial Game
	function initGame() {
		let cards = shuffle(cardDeck);
		deck.innerHTML = "";
		match = 0;
	
		const viewportMobile = window.matchMedia("(max-width: 576px)");
	
		const updateCardsPerRow = () => {
		match = 0;	
		cardsPerRow = viewportMobile.matches ? 4 : 6;
	
		const numRows = Math.ceil(cards.length / cardsPerRow);
		let newTemplateMarkup = Array.from({ length: numRows }).map((_, i) => `
			<div class="d-flex">
			${cards.slice(i * cardsPerRow, (i + 1) * cardsPerRow).map(card => `
				<div class="card" data-card-num="${card.cardNum}" data-card-name="${card.cardName}" data-card-relation="${card.cardRelation}">
				<div class="cardCont">
					<div class="cardFront"></div>
					<div class="cardBack" data-card-num="${card.cardNum}"></div>
					<img class="img-fluid img-hide" src="/static/images/activities-games/billy-memory-game/cardback_en.jpg">
				</div>
				</div>
			`).join('')}
			</div>
		`).join('');
	
		const oldCards = document.querySelectorAll('.card');
		oldCards.forEach(card => {
			card.remove();
			deck.innerHTML = "";
		});
	
		deck.insertAdjacentHTML('afterbegin', newTemplateMarkup);
		};
	
		viewportMobile.addEventListener('change', updateCardsPerRow);
		updateCardsPerRow();
	}

	// End Game
	function endGame() {
		initGame();
	}

	// Card Flip
	let previousElement = null;

	deck.addEventListener('click', (event) => {
		let clickedElement = event.target;
		const elementCard = clickedElement.closest('.card');

		if (elementCard === previousElement) {
			// Disable click on the same element
			event.preventDefault();
			return;
		}

		// Track the previously clicked element
		elementCard.previousElement = elementCard;

		if (!elementCard.classList.contains('match', 'open'))
		{
			// Stops when there are 2 cards open
			if (document.querySelectorAll('.show').length > 1) { return true; }

			let card = elementCard.innerHTML;
			let dataCardName = elementCard.getAttribute('data-card-name');
			let dataCardRelation = elementCard.getAttribute('data-card-relation');

			elementCard.classList.add('open','show', 'active', 'firstClicked');
			opened.push(card);

			let getCardBack = elementCard.querySelector('.cardBack:not(img)');
			let clonedCard = getCardBack.cloneNode(true);
			
			clonedCard.setAttribute('data-card-name', dataCardName);
			clonedCard.setAttribute('data-card-relation', dataCardRelation);
			photoCont.appendChild(clonedCard);

			// Compare with opened card
			if (opened.length > 1) 
			{
				if (card === opened[0]) 
				{
					setTimeout(() => {
						elementHTML[0].classList.add('noScroll');
						customModal.classList.add('modalShow');
						modalQuestionHeader.style.display = 'block';
					}, delay);

					let currentModalCard = photoCont.querySelectorAll('.cardBack');
					let currentModalCardFirst = currentModalCard[0];
					let currentModalCardSecond = currentModalCard[1];
					let currentModalCard1 = currentModalCardFirst.getAttribute('data-card-relation');
					let currentModalCard2 = currentModalCardSecond.getAttribute('data-card-relation');
					
					if (currentModalCard1 === currentModalCard2) {
						document.querySelector('.btnOptionYes').classList.add('correctAnswer');
					} else {
						document.querySelector('.btnOptionNo').classList.add('correctAnswer');
					}

					deck.querySelectorAll('.open').forEach(card => {
						card.classList.add('match', 'rubberBand');
					});

					setTimeout(() => {
						deck.querySelectorAll('.match').forEach(card => {
							card.classList.remove('show', 'rubberBand', 'open', 'firstClicked');
						});
					}, delay);
				}

				else 
				{
				deck.querySelectorAll('.open').forEach(card => {
						card.classList.add('notmatch', 'wobble');
				});

				setTimeout(() => {
						deck.querySelectorAll('.open').forEach(card => {
							card.classList.remove('wobble');
						});
				}, delay / 1.5);

				setTimeout(() => {
						deck.querySelectorAll('.open').forEach(card => {
							card.classList.remove('open', 'show', 'notmatch', 'active', 'firstClicked');
						});
				}, delay);

				photoCont.innerHTML = '';
				btnOptionYes.classList.remove('correctAnswer');
				btnOptionNo.classList.remove('correctAnswer');
				}

				opened = [];
			}
		}
	});
  
	initGame();
}
