import { viewportSize } from "./utils";
import Modal from 'bootstrap/js/dist/modal'


const body = document.querySelector('body');
const myMobileNavModal = new Modal(document.getElementById('mobileNavModal'));


function viewportChange(e) {
    if (e.matches) {
        myMobileNavModal.hide();
    }
}


mobileNavModal.addEventListener('show.bs.modal', event => {
    // Add a class when triggered
    body.classList.add('mobile-nav-modal-active');
})

mobileNavModal.addEventListener('hidden.bs.modal', event => {
    // Resets it
    body.classList.remove('mobile-nav-modal-active');
})


viewportSize().isLg.addListener(viewportChange);
